
import { defineAsyncComponent } from "vue";
import { Vue, Options } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import debounce from "lodash/debounce";
import { OnProcessSttController } from "@/app/ui/controllers/OnProcessSttController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { ON_PROCESS_STT } from "@/app/infrastructures/misc/RolePermission";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import router from "@/app/ui/router";
import { GTMOnProcessSttEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/on-process-stt-events";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
const DetailRejectPopup = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/shipment/booking/detail-booking/detail-reject-popup.vue"
    )
});
import { FlagsPermissionOnProcessstt } from "@/feature-flags/flags-permission-on-process-stt";

@Options({
  components: {
    DetailRejectPopup,
    OverlayPanel,
    Book
  }
})
export default class ShipmentBooking extends Vue {
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  mounted() {
    this.refs = this.$refs;
    OnProcessSttController.initOnProcessSttList();
  }

  get isDetailAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_edit.isEnabled();
  }
  get isEditAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_edit.isEnabled();
  }
  isEditAbleBasedOnAccountType(lastStatus: string) {
    if (this.dataProfile.isConsoleAccount) {
      return lastStatus === "STI";
    }
    return false;
  }
  get isCancelAble() {
    return FlagsPermissionOnProcessstt.on_process_stt_detail_cancel.isEnabled();
  }

  onRequest() {
    OnProcessSttController.fetchOnProcessSttList();
  }

  get firstRequest() {
    return OnProcessSttController.firstRequest;
  }

  get shipmentBooking() {
    return OnProcessSttController.onProcessSttData.data.map((item: any) => {
      return {
        ...item,
        customDeleteAction: this.isMigratingFromElexys
          ? false
          : this.isCancelAble &&
            this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
      };
    });
  }

  //Filter Search
  onSearch(value: string) {
    OnProcessSttController.setSearch(value);
    OnProcessSttController.resetPage();
    OnProcessSttController.fetchOnProcessSttList();
  }
  get searchValue() {
    return OnProcessSttController.search;
  }
  clearSearch() {
    OnProcessSttController.setSearch("");
    OnProcessSttController.fetchOnProcessSttList();
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    OnProcessSttController.setFilter(event);
    OnProcessSttController.resetPage();
    this.onRequest();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    OnProcessSttController.resetFilter();
    this.onRequest();
  }, 500);
  get advancedFilterData() {
    return OnProcessSttController.filter;
  }

  // Pagination Table
  get pagination() {
    return OnProcessSttController.onProcessSttData.pagination;
  }

  //Loading
  get isLoading() {
    return OnProcessSttController.isLoading;
  }

  //Error
  get isError() {
    return OnProcessSttController.isError;
  }

  //Error Cause
  get errorCause() {
    return OnProcessSttController.isErrorCause;
  }

  //misc
  refs: any = {};

  // Table
  columns = [
    {
      name: "No. STT",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<div class='text-left text-red-lp-200'>${item.sttNo}</div>`;
      }
    },
    {
      name: "Shipment ID",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.sttShipmentId}</div>`;
      }
    },
    {
      name: "Alamat Tujuan",
      styleHead: "w-64 text-left",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${ellipsisString(
          item.sttRecipientAddress,
          30
        )}</div>`;
      }
    },
    {
      name: "3LC",
      styleHead: "w-24 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.sttDestinationCity}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-56 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                      ${
                        item.sttLastStatusId?.toLowerCase() === "hnd"
                          ? "HANDOVER"
                          : item.sttLastStatusId
                      } (${item.sttCounter}/${item.sttTotalPiece})
                  </div>
                </div>`;
      },
      toolTip: (item: any) => {
        return `<div class='flex text-black-lp-300 text-12px max-w-xs'>${item.sttLastStatusIdDescription}</div>`;
      }
    },
    {
      name: "Total Koli",
      styleHead: "w-24 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.sttTotalPiece}</div>`;
      }
    },
    {
      name: "Total Berat",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${convertDecimalWithComma(
          item.sttChargeAbleWeight,
          2
        )} Kg</div>`;
      }
    },
    {
      name: "Produk",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
      }
    },
    {
      name: "Komoditas",
      styleHead: "w-52 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    ${ellipsisString(item.sttCommodityName, 25)}
                  </div>
                </div>`;
      }
    },
    {
      name: "Dibuat Untuk",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded capitalize px-2 py-0 bg-gray-lp-400">
                      ${ellipsisString(item.sttBilledTo, 90)}
                  </div>
                </div>`;
      }
    },
    {
      name: "Tanggal Booking",
      styleHead: "w-64 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.sttCreatedAt
        )}</div>
                <div class="capitalize text-gray-lp-500">${ellipsisString(
                  item.sttCreatedName,
                  30
                )}</div>`;
      }
    },
    {
      name: "Asuransi",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded capitalize px-2 py-0 bg-gray-lp-400">
                      ${item.sttInsuranceType}
                  </div>
                </div>`;
      }
    },
    {
      name: "Tipe COD",
      styleHead: "w-32 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex gap-2">
                  ${this.renderCodType(item)}
                </div>`;
      }
    },
    {
      name: "Status Bermasalah",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        if (item.sttWarningStatus) {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                      ${item.sttWarningStatus}
                  </div>
                </div>`;
        } else {
          return "-";
        }
      },
      toolTip: (item: any) => {
        if (item.sttWarningStatus) {
          return `<div class='flex text-black-lp-300'>${item.sttWarningStatusDescription}</div>`;
        } else {
          return "";
        }
      }
    },
    {
      name: "Action",
      key: "actionable_column",
      styleHead: "w-40 text-center",
      showButton: () => {
        return true;
      }
    }
  ];

  actionGroupOptions = (item: any) => {
    const language = item.sttProductType === "INTERPACK" ? "en" : "id";
    const groupOptions = [
      {
        icon: "pencil-alt-disable",
        label: "Ubah Booking",
        clickFunction: () => this.goToEdit(item.sttId)
      },
      {
        icon: "printer",
        label: "Print Thermal Resi",
        clickFunction: () => this.print(item.sttId, "thermal", language)
      },
      {
        icon: "document-download-outline-grey",
        label: "Print Thermal Resi PDF",
        clickFunction: () => this.print(item.sttId, "thermal-pdf", language)
      },
      {
        icon: "document-download-outline-grey",
        label: "Print Basic/Save to PDF",
        clickFunction: () => this.print(item.sttId, "basic", language)
      }
    ];
    if (
      this.isMigratingFromElexys ||
      !this.isEditAble ||
      !this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
    ) {
      return groupOptions.slice(1, 4);
    }
    return groupOptions;
  };

  async print(sttId: number, type: string, language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(sttId, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(sttId, 0, language, false, true),
      basic: () => book.default.methods.printBasic(sttId, 1, language),
      "commercial-invoice": () =>
        book.default.methods.directPrintInterpack(sttId)
    };

    printType[type]();
  }

  goToDetail(item: any) {
    GTMOnProcessSttEvent("on_process_stt_detail", {
      status: item.sttLastStatusId,
      sttNumber: item.sttNo
    });
    this.$router.push(`/shipment/on-process-stt/${item.sttId}`);
  }
  goToEdit(id: number) {
    router.push(`/shipment/on-process-stt/${id}/edit`);
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get listStatus() {
    if (this.dataProfile.isConsoleAccount) {
      return [
        { name: "ALL", value: "ALL" },
        { name: "STI", value: "STI" },
        { name: "STI DEST", value: "STI-DEST" },
        { name: "BAGGING", value: "BAGGING" },
        { name: "TRANSIT", value: "TRANSIT" },
        { name: "MISROUTE", value: "MIS-ROUTE" },
        { name: "HANDOVER", value: "HND" },
        { name: "DEL", value: "DEL" },
        { name: "DEX", value: "DEX" },
        { name: "CARGO PLANE", value: "CARGO PLANE" },
        { name: "CARGO TRUCK", value: "CARGO TRUCK" },
        { name: "CARGO TRAIN", value: "CARGO TRAIN" },
        { name: "CARGO SHIP", value: "CARGO SHIP" },
        { name: "REJECTED", value: "REJECTED" },
        { name: "REROUTE", value: "REROUTE" },
        { name: "MISBOOKING", value: "MISBOOKING" },
        { name: "RTS", value: "RTS" },
        { name: "RTSHQ", value: "RTSHQ" }
      ];
    }

    if (this.dataProfile.isSubConsoleAccount) {
      return [
        { name: "STI-SC", value: "STI-SC" },
        { name: "STI DEST-SC", value: "STI DEST-SC" },
        { name: "BAGGING", value: "BAGGING" },
        { name: "TRANSIT", value: "TRANSIT" },
        { name: "MISROUTE", value: "MIS-ROUTE" },
        { name: "CARGO PLANE", value: "CARGO PLANE" },
        { name: "CARGO TRUCK", value: "CARGO TRUCK" },
        { name: "CARGO TRAIN", value: "CARGO TRAIN" },
        { name: "CARGO SHIP", value: "CARGO SHIP" },
        { name: "REJECTED", value: "REJECTED" },
        { name: "REROUTE", value: "REROUTE" },
        { name: "MISBOOKING", value: "MISBOOKING" },
        { name: "RTS", value: "RTS" },
        { name: "RTSHQ", value: "RTSHQ" }
      ];
    }

    if (this.dataProfile.isPosParentAccount) {
      return [{ name: "PUP", value: "PUP" }];
    }

    if (this.dataProfile.isPosChildAccount) {
      return [
        { name: "PUP", value: "PUP" },
        { name: "PUP-C", value: "PUP-C" }
      ];
    }

    return [{ name: "", value: "" }];
  }
  isOpenSelectStatus = false;

  get filterStatus() {
    return OnProcessSttController.status;
  }

  onOpenSelectStatus() {
    this.isOpenSelectStatus = true;
  }
  onCloseSelectStatus() {
    this.isOpenSelectStatus = false;
  }
  onSelectStatus(name: string, value: string) {
    OnProcessSttController.setStatus(value);
    OnProcessSttController.resetPage();
    this.onRequest();
    this.onCloseSelectStatus();
  }

  // cancel booking
  isRejectPopup = false;
  sttNumberSelected = "";
  onOpenPopupCancelBooking(item: any) {
    this.sttNumberSelected = item.sttNo;
    this.isRejectPopup = true;
  }

  renderCodType(data: any) {
    const codType = this.renderDfod(data.sttIsCod, data.sttIsDfod);
    return codType 
      ? ChipsStyles({ status: "gray", title: codType, square: true })
      : "-"
  }

  renderDfod(cod: boolean, dfod: boolean) {
    let label;
    if (cod && dfod) {
      label = "DFOD"
    } else if (cod && !dfod) {
      label = "COD"
    }

    return label;
  }
}
